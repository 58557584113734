.spot-well {
  float: left;
  width: auto;
  height: 42px;
  margin-bottom: 24px;
  border-radius: 3px;
  
  &.well--full-container {
    width: 100%;
  }    
  &.spot-well--primary {
    background: rgba(240, 49, 49, 0.1);
    border: 1px solid rgba(240, 49, 49, 0.2);
    .spot-well-text--primary {
      margin-top: 10px;
      margin-bottom: 0;
      font-size: 14px;
      color: $color-spot-alert-well;
      > a {
        color: $color-spot-alert-well;
        @include font-weight(strong);
      }
    }
  }
}