.spot-content-flank {
  &.flank--left {
    float: left;
    width: 100%;
    max-width: 628px;
  }
  &.flank--right {
    float: right;
    width: 100%;
    max-width: 337px;
  }
}

.spot-panel-body {
  position: relative;
  padding: 16px 8px;

  &.panel-side--default {
    padding-right: 0;
    padding-left: 0;
  }
}

.spot-panel-groups {
  .panel {
    // overflow: hidden;
    background: transparent;
    @include box-shadow(none);
  }
  .panel-heading {
    padding-right: 8px;
    padding-left: 0;
    border-bottom: 1px solid rgba(51, 51, 51, 0.05);
  }
}

.panel-group-control {
  h6.panel-title {
    font-size: 14px;
    @include font-weight(strong);
  }
  .panel-title {
    > a {
      padding-left: 26px;
      display: block;
      text-decoration: none;
      &:before {
        content: "\41";
        font-family: $font-icon-spotlight;
        position: absolute;
        top: 50%;
        margin-top: -5px;
        left: 0;
        font-size: 11px;
        font-weight: 400;
        line-height: 1;
        color: $grey-light;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      &.collapsed {
        &:before {
          content: '\42';
        }
      }
    }
  }
}

.spot-preview--block,
.spot-preview--canvas {
  display: block;
  max-width: 100%;
  background-color: transparent;
}

.spot-preview--block {
  height: 354px;
}
.spot-preview--canvas {
  height: auto;
}

.spot-canvas-group {
  float: left;
  display: block;
  width: 100%;
  li {
    display: inline-block;
  }
}

// Change Log styling

.spot-changelog {
  position: absolute;
  top: 35px;
  right: 4.5rem;
  a {
    @include font-weight(strong);
    @include transition(all .1s ease-in);
    color: $grey-light;
    &:hover {
      color: $color-mini-info;
      text-decoration: none;
      i {
        color: $color-mini-info;
      }
    }
    > i {
      display: inline-block;
      font-size: 22px;
      color: $grey-light;
      vertical-align: middle;
      margin-left: 4px;
      line-height: .9;
      @include transition(all .1s ease-in);
    }
  } 
}

.spot-changelog-user {
  &.user-container {
    margin: 24px 16px;
    @extend %user__container;

    .user-container__image {
      &.user-container--var2 {
        background: $color-sidebar !important;
        color: $white !important;
      }
      @extend %user__photo;
    }
  }
}

.spot-panel-heading {
  padding-top: 24px !important;
  padding-bottom: 0 !important;
  padding-left: 12px;

  .panel-title {
    font-size: 14px;
    color: $color-grey-border;
  }
}

.spot-panel-body {
  &.panel-body--status {
    color: $color-grey-border;
    padding: 0 12px;
    font-size: 14px;
  }
}

// Custom file input gallery

.file-preview {
  border: none;
  margin-bottom: 0;
  .file-preview-frame {
    width: 100%;
    margin: 0;
    .file-preview-image {
      max-width: 100%;
      height: auto !important;
    }
  }
  .file-error-message {
    margin: 0;
    @include font-size(small);
  }
  > .close {
    top: -10px;
    right: -10px;
    @include border-radius(50px);
  }
}

#mainMediaContentImage {
  .file-input {
    position: relative;
    padding-top: 55px;
  }
  .btn-top--section {
    position: absolute;
    top: 0;
  }
  .kv-file-content {
    min-height: 354px;
    background-color: $grey-default;
    @include border-radius(4px);
  }
}

// .file-thumbnail-footer {
//   display: none;
// }



/* ------------------------------------------------------------------------------
*
*  # Panels component
*
*  Overrides for panels bootstrap component
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.panel-group .panel-heading+.panel-collapse>.panel-body, 
.panel-group .panel-heading+.panel-collapse>.list-group {
  border-top: none;
}
.panel {
  margin-bottom: 8px;
  border-width: 0;
  color: #333333;
  @include box-shadow(none);
}
.panel[class*=bg-] > .panel-heading {
  border-color: rgba(255, 255, 255, 0.2);
}
@media (max-width: 768px) {
  .panel[class*=bg-] > .panel-heading {
    background-color: inherit;
  }
}
.panel[class*=bg-].panel-flat > .panel-heading {
  border-bottom-color: transparent;
}
.panel[class*=bg-] > .panel-body {
  background-color: inherit;
}
.panel[class*=bg-] .panel-title {
  color: #fff;
}
.panel[class*=bg-] .table thead td,
.panel[class*=bg-] .table tbody td,
.panel[class*=bg-] .table thead th,
.panel[class*=bg-] .table tbody th {
  border-color: rgba(255, 255, 255, 0.1);
}
.panel[class*=bg-] .text-muted,
.panel[class*=bg-] .help-block,
.panel[class*=bg-] .help-inline {
  color: rgba(255, 255, 255, 0.8);
}
.panel-bordered {
  border-width: 1px;
}
.panel[class*=border-top-] {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.panel[class*=border-top-]:not(.border-top-lg):not(.border-top-xlg) {
  border-top-width: 1px;
}
.panel[class*=border-bottom-] {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.panel[class*=border-bottom-]:not(.border-bottom-lg):not(.border-bottom-xlg) {
  border-bottom-width: 1px;
}
.panel[class*=border-left-] {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.panel[class*=border-left-]:not(.border-left-lg):not(.border-left-xlg) {
  border-left-width: 1px;
}
.panel[class*=border-right-] {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.panel[class*=border-right-]:not(.border-right-lg):not(.border-right-xlg) {
  border-right-width: 1px;
}
.panel-body {
  position: relative;
}
.panel-flat > .panel-heading + .panel-body {
  padding-top: 0;
}
.panel-heading {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.panel-bordered > .panel-heading {
  margin: 0;
}
.panel-flat > .panel-heading {
  background-color: #fff;
}
.panel-flat > .panel-heading > .panel-title {
  margin-top: 2px;
  margin-bottom: 2px;
}
.panel-flat > .panel-heading + .tab-content > .has-padding {
  padding-top: 0;
}
.panel-flat[class*=bg-] > .panel-heading {
  background-color: inherit;
}
.panel-heading[class*=bg-],
.panel-primary .panel-heading,
.panel-danger .panel-heading,
.panel-success .panel-heading,
.panel-warning .panel-heading,
.panel-info .panel-heading {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.panel-white > .panel-heading {
  background-color: #fff;
  border-bottom-color: #ddd;
}
.panel-title {
  position: relative;
  font-size: 13px;
}
a.panel-title {
  display: block;
}
.panel-title img {
  max-height: 20px;
  display: inline-block;
  vertical-align: top;
}
.panel-title > small:not(.display-block),
.panel-title > .small:not(.display-block) {
  margin-left: 5px;
}
h1.panel-title,
.h1.panel-title {
  font-size: 25px;
}
h2.panel-title,
.h2.panel-title {
  font-size: 23px;
}
h3.panel-title,
.h3.panel-title {
  font-size: 21px;
}
h4.panel-title,
.h4.panel-title {
  font-size: 19px;
}
h5.panel-title,
.h5.panel-title {
  font-size: 17px;
}
h6.panel-title,
.h6.panel-title {
  font-size: 15px;
}
.icons-list a[data-action] {
  vertical-align: middle;
}
.icons-list a[data-action]:after {
  font-family: 'icomoon';
  font-size: 16px;
  min-width: 16px;
  text-align: center;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icons-list a[data-action="collapse"]:after {
  content: '\e9c1';
}
.icons-list a[data-action="reload"]:after {
  content: '\e9fb';
}
.icons-list a[data-action="close"]:after {
  content: '\e9b6';
}
.icons-list a[data-action="move"]:after {
  content: '\e986';
}
.icons-list a[data-action="modal"]:after {
  content: '\e9eb';
}
.panel-footer {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel-footer:after {
  content: '';
  display: table;
  clear: both;
}
.panel-footer .form-control,
.panel-footer .selectboxit-btn,
.panel-footer .multiselect.btn-default,
.panel-footer .bootstrap-select .btn-default,
.panel-footer .select2-selection--single:not([class*=bg-]):not([class*=border-]) {
  border-bottom-color: transparent!important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.panel-footer-transparent {
  background-color: transparent;
  border-top: 0;
  padding-top: 0;
  padding-bottom: 11px;
}
.panel-footer-condensed {
  padding-top: 2px;
  padding-bottom: 2px;
}
.panel-footer-bordered {
  background-color: #fff;
  padding-right: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive {
  border-top-width: 0;
}

.panel-group-control.panel-group-control-right .panel-title > a {
  padding-left: 0;
  padding-right: 26px;
}
.panel-group-control.panel-group-control-right .panel-title > a:before {
  left: auto;
  right: 0;
}
.panel-default {
  border-color: transparent;
}
.panel-primary {
  border-color: transparent;
}
.panel-primary.panel-bordered {
  border-color: #2196F3;
}
.panel-success {
  border-color: transparent;
}
.panel-success.panel-bordered {
  border-color: #4CAF50;
}
.panel-info {
  border-color: transparent;
}
.panel-info.panel-bordered {
  border-color: #00BCD4;
}
.panel-warning {
  border-color: transparent;
}
.panel-warning.panel-bordered {
  border-color: #FF5722;
}
.panel-danger {
  border-color: transparent;
}
.panel-danger.panel-bordered {
  border-color: #F44336;
}