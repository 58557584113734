// Spotlight palette
//
// -----------------
$color-spot-alert:        #F03131;
$color-spot-alert-well:   #a32020;
$color-spot-success:      #14b876;
$color-spot-pink:         #c95aa4;
$color-spot-orange:       #f06131;
$color-spot-blue:         #3981eb;
$color-spot-dark-blue:    #58595b;
$color-spot-grey:         #EEE;
$color-green-dark:        #0e8052;
$color-grey-20:           #F2F2F2;
$color-grey-80:           #F8F8F8;
$color-grey-border:       #B2B2B2;
$color-grey-light-blue:   #f3f5f7;
$color-grey-900:          #333;

// Monochrome
//
// -----------------
$white:                   #FFFFFF;
$black:                   #000000;
$grey-light-blue:         $color-grey-light-blue;
$grey-dark:               $color-grey-900;
$grey-light:              $color-grey-border;
$grey-default:            $color-spot-grey;

// Alert
//
// -----------------
$alert-danger:                  $color-spot-alert;
$alert-success:                 $color-spot-success;

// Color link
//
// -----------------
$color-link:                    $color-spot-blue;
$color-sidebar:                 $color-spot-orange;
$color-selected-table:          $color-spot-orange;
$color-mini-info:               $color-spot-dark-blue;
$color-text-highlight-success:  $color-green-dark;

// Sidebar
//
// -----------------
$bgcol-sidebar:                 $color-grey-80;
$bgcol-default:                 $color-grey-80;

// Color border
//
// -----------------
$border-grey-light:             $color-grey-20;
$border-grey-strong:            $color-grey-border;

$panel-font-size:               13px;
$panel-line-height:             1.5;
$panel-line-to-show:            2;


// Extends styles
//
// -----------------

// Extends gradient
%spot__gradient {
  background: -webkit-linear-gradient(90deg, $color-spot-pink, $color-spot-orange);
  background: -moz-linear-gradient(90deg, $color-spot-pink, $color-spot-orange);
  background: linear-gradient(90deg, $color-spot-pink, $color-spot-orange);
}

// Extends list group
%spot__list-group {
  padding: 0;
  list-style: none;
}

// Extends panel login
%panel__login{
  padding: 0;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

// Extends panel forgot
%panel__forgot {
  height: auto;
  min-height: 200px;
  padding-top: 52px;
}

// Extends title form
%form__title {
  font-size: 16px;
  color: $color-grey-900;
  text-align: center;
  margin-top: 0;
  margin-bottom: 4px;
}

// Extends text form
%form__text {
  font-size: 12px;
  font-weight: 100;
  color: $color-spot-dark-blue;
  text-align: center;
  margin: 0 0 32px;
}

// Extends success state
%success {
  font-size: 12px;
  color: $alert-success;
  text-align: center;
  margin-top: 0;
  margin-bottom: 12px;
}

// Extends inline
%list__inline {
  display: inline-block;
}

// Extends user profile
%user__container {
  width: auto;
  height: 100%;
  min-height: 32px;
  overflow: hidden;
  margin: 5px 8px;
  @include border-radius(100px);
}

%user__photo {
  width: 32px;
  height: 32px;
  color: #f06131;
  background: #F2F2F2;
  text-align: center;
  line-height: 32px;
  font-weight: bold;
  font-size: 13px;
}

.reset-border {
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-left: none !important;
}
