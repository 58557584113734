label {
  font-weight: normal;
}

// Textarea styling
.spot-textarea {
  @extend .spot-input__text;
  min-height: 104px;
  overflow-y: hidden;
  resize: none;
}