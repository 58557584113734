.spot-button {
  position: relative;
  display: block;
  padding: 9px 0;
  color: $white;
  line-height: 1.4;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 3px;
  z-index: 1;
  @include transition(all .2s ease-in);

  &:hover,
  &:focus {
    text-decoration: none;
    color: $white;
    @include box-shadow(0 3px 5px 0px rgba(0,0,0,0.3));
  }
  &.btn--large{
    width: 100%;
  }
  &.btn--small{
    min-width: 127px;
    padding: 11px 16px;
    @include font-size(small);
  }
  &.btn--mini {
    width: 100px;
    font-size: 11px;
  }
  &.btn--inline {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
  }
  &.btn--primary {
    @extend %spot__gradient;
    border: 1px solid $color-sidebar;
  }
  &.btn--secondary-var1 {
    display: block;
    color: $color-sidebar;
    background: transparent;
    border: 1px solid $color-sidebar;
  }
  &.btn--secondary-var2 {
    display: block;
    color: $color-spot-dark-blue;
    background: transparent;
    border: 1px solid $color-spot-dark-blue;
  }
  &.btn--transparent {
    position: relative;
    display: block;
    width: 100%;
    padding: 10px 10px 10px 40px;
    outline: none;
    border: none;
    border-radius: 0;
    background: transparent;
    color: $color-grey-800;
    text-align: left;

    &:hover,
    &:focus {
      background: $color-grey-20;
      @include box-shadow(none);
    }
    i {
      position: absolute;
      top: 11px;
      left: 16px;
    }

  }
}

// Not best practice here... 
a[role='button'].spot-button {
  &.btn--transparent {
    display: block;
    padding-left: 40px !important;
    text-decoration: none;
    color: $color-grey-800;
  }
}

input[type="file"] {
  display: none;
}

.btn--file {
  float: left;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  background-color: $white;
  color: $grey-dark;
  &:hover,
  &:focus,
  &:active {
    color: $grey-dark;
  }

  + label {
    margin: 16px 0;
    background-color: $white;
    border: none;
    color: $grey-dark;

    &:hover,
    &:focus,
    &:active {
      color: $grey-dark;
    }

    > i.spoticon-attach {
      font-size: 13px;
      vertical-align: middle;
      margin-right: 4px;
    }
  }
}

.btn-file--gallery {
  i {
    &:before {
      margin-right: 4px;
      display: inline-block;
      @include translate(0, 1px);
    }
  }
}

.spot-button-group {
  .spot-button-group__inline {
    display: inline-block;
    width: 49%;
    &--default{
      display: inline-block;
    }
  }
}

a[role="button"] {
  &.spot-button {
    // padding: 12px 0;
    i.icon {
      font-size: 11px;
    }
  }
}

// Buttons group for controlling croppper
.btn--zoom {
  display: inline-block;
  padding: 0px 6px;
  background-color: $color-sidebar;
  @include border-radius(3px);

  .icon {
    font-size: 10px;
    vertical-align: middle;
  }
}
