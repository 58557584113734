/* Anno.js by @iamdanfox */
.anno {
  position: absolute;
  padding: 15px;
  z-index: 5000;
  width: 300px;
  cursor: default;
  text-transform: none;
  text-align: left;
  line-height: 0.9em;
  -webkit-transition: all 300ms cubic-bezier(0.68, 0, 0.265, 1);
  -moz-transition: all 300ms cubic-bezier(0.68, 0, 0.265, 1);
  -ms-transition: all 300ms cubic-bezier(0.68, 0, 0.265, 1);
  -o-transition: all 300ms cubic-bezier(0.68, 0, 0.265, 1);
  transition: all 300ms cubic-bezier(0.68, 0, 0.265, 1);
  -webkit-transition-property: opacity, margin;
  -moz-transition-property: opacity, margin;
  transition-property: opacity, margin;
}
.anno .anno-inner {
  position: relative;
  background: white;
  -webkit-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.35);
}
.anno .anno-inner .anno-content {
  padding: 15px 20px;
  line-height: 1.3em;
  font-family: sans-serif;
  font-weight: normal;
  font-size: 15px;
  color: #56565a;
}
.anno .anno-inner .anno-btn-container {
  text-align: right;
}
.anno .anno-inner .anno-btn-container .anno-btn {
  margin: 0 20px 15px -10px;
  padding: 7px 14px;
  height: auto;
  width: auto;
  border: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: white;
  @include transition(all 150ms ease-in-out);
  @extend %spot__gradient;
  // -webkit-transition: all 150ms cubic-bezier(0.68, 0, 0.265, 1);
  // -moz-transition: all 150ms cubic-bezier(0.68, 0, 0.265, 1);
  // -ms-transition: all 150ms cubic-bezier(0.68, 0, 0.265, 1);
  // -o-transition: all 150ms cubic-bezier(0.68, 0, 0.265, 1);
  // transition: all 150ms cubic-bezier(0.68, 0, 0.265, 1);

  &:hover,
  &:active {
    opacity: .9;
    @extend %spot__gradient;
  }
}
.anno .anno-inner .anno-btn-container .anno-btn.anno-btn-low-importance {
  background: none;
  color: #bbbbbd;
  &:hover {
    color: #78787b;
  }
}
.anno .anno-arrow {
  position: absolute;
  border: 8px solid white;
}
.anno.anno-target-center-bottom .anno-arrow,
.anno.anno-target-bottom .anno-arrow {
  top: 100%;
  left: 20px;
  border-bottom: none;
  border-color: white transparent;
}
.anno.anno-target-center-bottom.anno-hidden,
.anno.anno-target-bottom.anno-hidden {
  margin-top: -20px;
}
.anno.anno-target-center-left .anno-arrow,
.anno.anno-target-left .anno-arrow {
  right: 100%;
  top: 15px;
  border-left: none;
  border-color: transparent white;
}
.anno.anno-target-center-left.anno-hidden,
.anno.anno-target-left.anno-hidden {
  margin-left: 20px;
}
.anno.anno-target-center-top .anno-arrow,
.anno.anno-target-top .anno-arrow {
  bottom: 100%;
  left: 20px;
  border-top: none;
  border-color: white transparent;
}
.anno.anno-target-center-top.anno-hidden,
.anno.anno-target-top.anno-hidden {
  margin-top: 20px;
}
.anno.anno-target-center-right .anno-arrow,
.anno.anno-target-right .anno-arrow {
  left: 100%;
  top: 15px;
  border-right: none;
  border-color: transparent white;
}
.anno.anno-target-center-right.anno-hidden,
.anno.anno-target-right.anno-hidden {
  margin-left: -20px;
}
.anno.anno-target-center-left .anno-arrow,
.anno.anno-target-center-right .anno-arrow {
  top: 50%;
  margin-top: -8px;
}
.anno.anno-target-center-bottom .anno-arrow,
.anno.anno-target-center-top .anno-arrow {
  left: 50%;
  margin-left: -8px;
}
.anno.anno-hidden {
  opacity: 0;
}
.anno.anno-width-400 {
  width: 400px;
}
.anno.anno-width-250 {
  width: 250px;
}
.anno.anno-width-200 {
  width: 200px;
}
.anno.anno-width-175 {
  width: 175px;
}
.anno.anno-width-150 {
  width: 150px;
}
.anno-overlay {
  position: fixed;
  z-index: 99;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  /*pointer-events:none; // allows clickthrough*/
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 300ms cubic-bezier(0.68, 0, 0.265, 1);
  -moz-transition: all 300ms cubic-bezier(0.68, 0, 0.265, 1);
  -ms-transition: all 300ms cubic-bezier(0.68, 0, 0.265, 1);
  -o-transition: all 300ms cubic-bezier(0.68, 0, 0.265, 1);
  transition: all 300ms cubic-bezier(0.68, 0, 0.265, 1);
}
.anno-overlay.anno-hidden {
  pointer-events: none;
  opacity: 0;
}
.anno-emphasise-target {
  z-index: 1001 !important !important;
}
