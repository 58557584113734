.label {
  padding: .7em .6em .8em;
  font-size: 11px;
  
  &.spot-label--success {
    display: block;
    background: $alert-success;
  }
  &.spot-label--default {
    display: block;
    // background: $alert-success;
  }
}