@import "../themes/_palette.scss";

body {
  font-family: $font-primary;
  font-size: 16px;
  color: $color-grey-900;
}
.spot-bg {
  // position: relative;
  background-color: $grey-light-blue;
}
.text--strong {
  color: $color-grey-900;
  @include font-weight(strong);
}
.text--warning {
  color: $alert-danger;
}
.text__muted--top {
  font-size: 13px;
  margin-bottom: 0;
  color: $color-grey-900;
}
.text__based {
  font-size: 28px;
  line-height: 1.32;
  margin: 0 0 4px;
  @include font-weight(strong);
}
.text__label {
  margin-bottom: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $grey-light;
}
.text__state {
  font-size: 11px;
  @include font-weight(strong);

  &.text--success {
    color: $alert-success;
  }
  
  &.text--alert {
    color: $alert-danger;
  }

  &.text--disable {
    color: $color-grey-border;
    @include font-weight(light);
  }

  &.text__state--info {
    color: $color-mini-info;
    font-style: italic;
    @include font-weight(light);
  }
}
.is-hide {
  display: none;
}
.is-hide-visible {
  visibility: hidden;
}
.spot-list-group {
  margin-bottom: 0;
}
ul,
ol {
  list-style: none;
}
.page-container {
  margin-top: 77px;
}
.panel--transparent {
  background-color: transparent;
}

.spot-label--full {
  @include element-width(full-width);
}
.spot-label--inline {
  @include element-width(inline);
}

// Modal styling
.spot-modal__content {
  background: $color-grey-20;

  .spot-label__container {
    @extend .spot-label__container;
    > p {
      @include font-size(small);
    }
  }  
  .modal-header {
    padding: 24px 24px 32px;
  }
  .spot-modal__body {
    padding: 0 24px;
  }
  .modal-footer {
    padding: 0 24px 24px;
  }
}

// Log styling
.spot-log__group {
  .log__group {
    &--text {
      font-size: 14px;
      margin-bottom: 24px;
      line-height: 22px;

      &-highlight-success {
        font-size: 14px;
        color: $color-text-highlight-success;        
        background-color: rgba(20, 184, 118, 0.1);
        margin: 0 -17px 24px;
        padding: 10px 17px;
      }
    }
  }
}

.centered-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  top: unset;
  bottom: unset;
}

.flex {
  display: flex;

  &.flex-centered {
    justify-content: center;
    align-items: center;

    .logo-notif {
      display: flex;
      min-width: 60px;
      justify-content: center;
      align-items: center;
    }
  }
}

.overflow-unset {
  overflow: unset !important;
}

// Overlay View for Disable on Mobile Version
.overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 120;
  @extend %spot__gradient;

  .overlay-container {
    position: absolute;
    top: 50%;
    left: 50%;
    @include translate(-50%, -50%);

    .overlay-text {
      padding-right: 10px;
      padding-left: 10px;
      font-size: 18px;
      font-family: $font-primary;
      color: $white;
      text-align: center;
      margin-bottom: 0;
    }
  }
}

.absolute {
  position: absolute;
  &.absolute-left--case {
    left: 7em;
  }
}