.spot-input-group {
  &--inline {
    margin-top: 16px;

    li {
      display: inline-block;
      margin-right: 36px;

      .radio-group {
        position: relative;
        cursor: pointer;

        input[type="radio"] {
          display: none;
          
          &:checked + label:after {
            content: '';
            position: absolute;
            top: 7px;
            left: 4px;
            display: block;
            width: 0;
            height: 0;
            border: 4px solid $color-spot-orange;
            opacity: 1;          
            @include border-radius(5em);
            @include scale(1);
          }

          label:after {
            content: '';
            opacity: 0;
            @include scale(0);
            @include transition(ease-in 0.4s all);
          }
        }

        .spot-input {
          &__radio {
            &:before {
              content: '';
              position: absolute;
              top: 3px;
              left: 0;
              display: inline-block;
              width: 16px;
              height: 16px;
              @include border-radius(5em);
            }

            &--primary {
              &:before {
                content: '';
                border: 2px solid $color-spot-orange;
              }
            }
          }
        }
        label {
          padding-left: 20px;
          cursor: pointer;
        }
      }
    }
    .spot-radio {
      display: none;

      &:checked + label:after {
        content: '';
        display: block;
        color: $white;        
        line-height: 13px;
        background: $grey-light;
        border-bottom: 2px solid $grey-light;
      }

      // Color danger
      &--danger {
        &:checked + label:after {
          background: $color-selected-table;
          border-bottom: 2px solid $color-selected-table;
        }
      }      

      + label {
        font-size: 14px;
        padding: 10px 14px;
        line-height: 30px;
        color: $color-grey-800;
        cursor: pointer;
      }
    }
  }
  &--block {
    li {
      display: block;
    }
  }
}