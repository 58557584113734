.panel {
  &.panel--transparent {
    @include box-shadow(none);
  }
  .panel-body {
    padding: 0;
  }
}

.nav {
  &.nav-tabs {
    li,
    li.active {
      a {
        padding: 10px 0;
        margin: 0 15px;
        background-color: transparent;
        border: none;
        font-size: 14px;
        font-weight: 600;
        color: $border-grey-strong;
      }
      &.active {
        a {
          color: $color-grey-900;
          border-bottom: 1px solid $alert-danger;
        }
      }
    }
    .badge {
      margin-top: -3px;
      margin-left: 3px;
      &.badge-warning {
        background-color: $color-selected-table;
      }
    }
  }
}

.nav-tabs--inline {
  display: inline-block;
}