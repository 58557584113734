.spot-modal {
  &.modal.fade .modal-dialog {
    // top: 30%;
    opacity: 0;
    @include scale(0.1);
    @include transition(all 0.3s);
  }
  &.modal.fade.in .modal-dialog {
    opacity: 1;
    @include scale(1);
    
    &.dialog--offset-top-50 {
      @include translate3d(0, -51%, 0);
    }
  }
}
.modal-backdrop {
  z-index: 98;
}
.modal {
  z-index: 100;
}
.dropdown-menu {
  margin: -5px 0;
  padding: 0;
}
.spot-input--transparent {
  background-color: transparent;
  border: none;
}

#modalInitAlert {
  .spot-modal__content {
    background: $white;
    border: none;
  }
  .modal-content {
    @include box-shadow(0 1px 3px rgba(0, 0, 0, 0.1) !important);
  }
}

#modalInitAlertUser {

  .close {
    font-size: 12px;
    &--right-top {
      position: absolute;
      top: 4px;
      right: -8px;
    }
  }
  
  .spot-modal__content {
    background: $white;
    border: none;
  }

  .modal-header {
    padding: 20px
  }

  .modal-dialog {
    width: 100%;
    transform: none;
  }

  .modal-content {
    max-width: 600px;;
    position: fixed;
    bottom: 1.8em;
    right: 2em;
    @include box-shadow(0 1px 3px rgba(0, 0, 0, 0.1) !important);
  }
}