.hero {
  position: relative;
  width: 100%;
  max-width: 1000px;

  .main__video-player {
    display: block;
    position: relative;
    height: 0;
    padding-top: 25px;
    padding-bottom: 56.25%;
    overflow: hidden;
    clear: both;

    img {
      position: absolute;
      top: 0;
      z-index: 1;
    }
  
    .img--block {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    
    iframe {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }

    .video-player__container {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
        
      &:hover {
        > .icon__player {
          @include scale(1.2);
        }
      }

      .icon__player {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100px;
        height: 100px;
        margin: auto;
        border: 5px solid #fff;
        border-radius: 100%;
        color: #fff;
        text-align: center;
        z-index: 2;
        @include transition(.2s ease-in);

        .icon-video-play {
          display: inline-block;
          width: 100%;
          height: 100%;
          font-size: 45px;
          text-align: center;
          margin: auto;
          line-height: 8.25rem;

          > .fa {
            margin-left: 8px;
            @include translate(50%, 0);
          }
        }
      }
    }
  }  
}

.media-list {
  .media-default-list {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.canvas {
  &--reaction {
    max-width: 100%;
    width: 135px;
  }
}

.flex-group {
  &-inline {
    display: inline-flex;
    justify-content: flex-start;
    &--column {
      flex-direction: column;
    }
  }
}
