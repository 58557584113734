.note-btn-group {
  &.note-style,
  &.note-color,
  &.note-table, 
  &.note-insert,
  &.note-view {
    // display: none;
  }
}

.note-editor {
  background-color: $white !important;
  @include border-radius(5px !important);

  .note-toolbar {
    &.panel-heading {
      padding: 0 16px;
      margin-bottom: 8px;
      background-color: transparent;
      border: none;
    }
  }
  .note-editing-area {
    padding: 16px;
    font-size: 14px;
  }
  .note-statusbar {
    display: none;
  }
}

.note-btn {
  margin-right: 8px;
  background-color: $color-grey-80;
  border: none;
  @include border-radius(3px !important);
}