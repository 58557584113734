select.bs-select-hidden,
select.selectpicker {
  display: none !important;
}

.bootstrap-select>select {
  position: absolute!important;
  bottom: 0;
  left: 50%;
  display: block!important;
  width: .5px!important;
  height: 100%!important;
  padding: 0!important;
  opacity: 0!important;
  border: none;
}

.select-container {
  width: 190px;
  margin: 16px auto 0;
}

.bootstrap-select {
  width: 100% !important;

  > .btn {
    width: 100%;
    padding-right: 43px;
  }

  .btn-default {
    padding: 8px 0;
    padding-right: 24px;
    background-color: transparent;
    border: 1px solid transparent;
    border-width: 0 0 1px;
    font-weight: 400;
    text-transform: none;
    border-bottom-color: none;
    border-radius: 0;
    @include box-shadow(none);

    &:focus,
    &:active,
    &.focus,
    &.active {
      background-color: transparent;
      border-bottom-color: transparent;
      @include box-shadow(0 1px 0 transparent);
    }

    &.disabled,
    &[disabled] {
      border-bottom-style: dashed;
      background-color: transparent;
      border-bottom-color: #ddd;
      @include box-shadow(none);
    }
  }
  fieldset[disabled] .boostrap-select {
    .btn-default {
      border-bottom-style: dashed;
      background-color: transparent;
      border-bottom-color: #ddd;
      @include box-shadow(none);
    }
  }
  
  .btn {
    .caret {
      &::before {
        position: absolute;
        top: 0;
        color: #333;
        content: '\e9c5';
        font-family: 'icomoon';
        display: block;
        font-size: 16px;
        width: 16px;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    .filter-option {
      color: #333;
      display: inline-block;
      overflow: hidden;
      width: 100%;
      text-align: left;
      text-overflow: ellipsis;
    }
  }
  &.form-control {
    margin: 0;
    padding: 0;
    &:not([class*="col-"]) {
      width: 100%;
    }
  }
  &.btn-group {
    &.open {
      >.button-default {
        &.dropdown-toggle {
          border: none;
          background-color: transparent;
          @include box-shadow(none);
        }
      }

      .dropdown-toggle {
        @include box-shadow(none);
      }
    }
    .dropdown-menu {
      top: 3em;
      min-width: 100%;
      margin: 1px 0 0;
      border: none;
      @include border-radius(3px);
      @include box-shadow(0 2px 13px 1px rgba(0, 0, 0, 0.1));
      >li {
        >a {
          padding: 12px 20px;
          cursor: pointer;
          outline: none;
          color: $color-mini-info;
        }
      }
      >.selected {
        >a {
          background-color: #c95aa4;
          color: $white;
        }
      }
    }
  }
}
.bootstrap-select.btn-group.open>.btn-default.dropdown-toggle {
  border-bottom-color: #c95aa4;
  @include box-shadow(0 1px 0 #c95aa4)
}
.caret {
  border: none;
}

// .bootstrap-select .popover-title {
//   font-size: 11px;
//   line-height: 1.82;
//   margin-top: 8px;
//   margin-bottom: 1px;
//   font-weight: 400;
//   padding: 8px 16px;
//   color: #999999;
// }

// .bootstrap-select.fit-width {
//   width: auto !important;
// }

// .bootstrap-select.btn-group.bs-container {
//   position: absolute;
// }

.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0;
}

.bootstrap-select.btn-group.dropdown-menu-right,
.bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
  float: right;
}

.bootstrap-select.btn-group>.disabled {
  cursor: not-allowed;
}

// .bootstrap-select.btn-group>.disabled:focus {
//   outline: 0;
// }

// .bootstrap-select.btn-group .btn .filter-option>i,
// .bootstrap-select.btn-group .dropdown-menu>li>a [class*=icon-] {
//   margin-right: 16px;
//   float: left;
//   margin-top: 2px;
//   top: 0;
// }

.bootstrap-select.btn-group[class*="col-"] .btn {
  width: 100%;
}

.bootstrap-select.btn-group .btn-default .caret {
  right: 0;
}

.bootstrap-select.btn-group .btn-lg:not(.btn-default) {
  padding-right: 43px;
}

.bootstrap-select.btn-group .btn-lg:not(.btn-default)>.caret {
  right: 18px;
}

.bootstrap-select.btn-group .btn-sm:not(.btn-default) {
  padding-right: 37px;
}

.bootstrap-select.btn-group .btn-sm:not(.btn-default)>.caret {
  right: 14px;
}

.bootstrap-select.btn-group .btn-xs:not(.btn-default) {
  padding-right: 34px;
}

.bootstrap-select.btn-group .btn-xs:not(.btn-default)>.caret {
  right: 12px;
}

.bootstrap-select.btn-group .dropdown-menu>li>a .check-mark {
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 16px;
  margin-right: 0;
  color: transparent;
}

// .bootstrap-select.btn-group .dropdown-menu>li>a .text {
//   display: inline-block;
// }

// .bootstrap-select.btn-group .dropdown-menu>li>a>.label,
// .bootstrap-select.btn-group .dropdown-menu>li>a>.badge {
//   float: none;
// }

.bootstrap-select.btn-group .dropdown-menu>li>a.special-class,
.bootstrap-select.btn-group .dropdown-menu>li>a.special-class:hover {
  font-weight: 700;
  color: #fff;
  background-color: #F44336;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.bootstrap-select.btn-group .dropdown-menu>li.disabled a {
  cursor: not-allowed;
}

.bootstrap-select.btn-group .dropdown-menu>li small {
  padding-left: 10px;
}

.bootstrap-select.btn-group .dropdown-menu>.selected>a small {
  color: #fff;
}

.bootstrap-select.btn-group .dropdown-menu.inner {
  position: static;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.bootstrap-select.btn-group .dropdown-menu .notify {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 8px 16px;
  background-color: #f8f8f8;
  border-top: 1px solid transparent;
  pointer-events: none;
}

.bootstrap-select.btn-group .dropdown-header~li>a {
  padding-left: 32px;
}

.bootstrap-select.btn-group .no-results {
  padding: 8px 16px;
  background: #f8f8f8;
  margin-bottom: -8px;
  border-top: 1px solid transparent;
}

.bootstrap-select.btn-group.fit-width .btn .filter-option,
.bootstrap-select.btn-group.fit-width .btn .caret {
  position: static;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu>li a .text {
  margin-right: 48px;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu>li.selected a .check-mark {
  color: #fff;
}

.input-group .bootstrap-select>.btn {
  border-radius: 0;
}

.error .bootstrap-select .btn {
  border: 1px solid #F44336;
}

.control-group.error .bootstrap-select .dropdown-toggle {
  border-color: #F44336;
}

.mobile-device {
  position: absolute;
  top: 0;
  left: 0;
  display: block !important;
  width: 100%;
  height: 100% !important;
  opacity: 0;
}