.spot-pagination {
  display: inline-block;
  padding: 8px;
  margin: 20px 0;
  border-radius: 4px;
  background-color: $white;

  >li {
    >a:hover,
    >a:focus, 
    >span:hover, 
    >span:focus {
      @include border-radius(3px);
    }
  }

  >li>a,
  >li>span {
    position: relative;
    float: left;
    padding: 10px 12px;
    font-size: 12px;
    line-height: 1;
    text-decoration: none;
    color: $grey-dark;
    background-color: #fff;
    border: none;
    margin: 0 2px;
  }
  .active>a,
  .active>a:hover,
  .active>a:focus,
  .active>span,
  .active>span:hover,
  .active>span:focus {
    z-index: 3;
    color: #fff;
    background-color: $color-selected-table;
    border-color: transparent;
    cursor: default;
    @include border-radius(3px);
  }
}