#mainChannelIcon {
  .file-input {
    position: relative;
    padding-top: 55px;
  }
  .btn-top--section {
    position: absolute;
    top: 0;
  }
  .kv-file-content {
    width: 135px;
    height: 135px;
  }
  .file-preview {
    .file-preview-frame {
      max-width: 135px;
      height: 135px;
      background-color: $grey-default;
      @include border-radius(4px);
    }
  }
}