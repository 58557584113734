.content-wrapper {
  padding-left: 230px;
}

.content-fluid-wrapper {
  width: 100%;
  margin: auto;
}

.spot-content {
  position: relative;
  padding: 27px 24px 30px;
  color: $color-grey-800;

  &.spot-content-light {
    background-color: $white;
  }

  .spot-content__title {
    margin: 7px 7px 35px;
    margin-left: 0;
    font-size: 24px;
    font-weight: 100;
    text-transform: uppercase;
  }

  .spot-panel__container {
    position: relative;
    width: 100%;
    height: 100%;

    .spot-panel__title {
      margin: 0 8px 8px;
      font-size: 16px;
      @include font-weight(light);
    }

    .spot-panel {
      position: relative;
      padding: 0 0 12px;

      .spot-panel__group {
        position: relative;
        margin-bottom: 22px;
        height: 100%;
        
        &--box {
          min-height: 490px;
        }
        
        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 7.5px;
          bottom: 0;
          left: 7.5px;
          background-color: $white;
          z-index: -1;
          @include border-radius(5px);
        }

        .spot-panel--top {
          border-bottom: 1px solid $border-grey-light;
          margin: 0 7px;
        }

        .spot-panel--bottom {
          padding: 16px 24px 1px;
          &.spot-panel--with-bg {
            background: url('../../assets/images/icon-asset-ga-wide@2x.png') no-repeat right bottom;
            background-size: contain;
          }

          // List group in content story article management
          &.spot-panel__list-item {
            padding: 0;

            .media {
              padding: 16px 16px 14px;
              background-color: $white;
              @include border-radius(4px);

              &:hover {
                background-color: $grey-default;
                @include transition(all .2s ease-in-out);
                .media-annotation {
                  display: block;
                  @include transition(all .2s ease-in-out);
                }
              }

              .media-annotation {
                display: none;
                >a {
                  color: $color-mini-info;
                  text-decoration: none;
                }
              }
            }
          }

          > h5 {
            margin: 0 0 16px;
          }
          .thumb {
            width: 40px;
            height: 40px;
            .media-preview {
              @include border-radius(3px);
            }
          }
          .spot-media--left {
            float: left;
            display: block;
            display: -webkit-box;
            max-width: 300px;
            height: $panel-font-size*$panel-line-height*$panel-line-to-show;
            margin: 0;
            font-size: $panel-font-size;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: $panel-line-to-show;
            line-height: $panel-line-height;
            text-overflow: ellipsis;
            overflow: hidden;
            @include transition(all .1s ease-in);

            @media (max-width: 1199px) {
              max-width: 65%;
            }
          }
          a.spot-media--left {
            &:hover,
            &:focus {
              color: $color-sidebar;
              text-decoration: none;
            }
          }
          .media-annotation {
            margin: 12px 0;
          }
        }
      }
      &.spot-panel--inline {
        float: left;
        width: 50%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
      }

      &.spot-panel--full {
        float: left;
        width: 100%;
      }

      // Main panel with list group
      //
      // -------------
      &.spot-panel__list-group {
        .spot-panel__group {
          &:before {
            right: 0;
            left: 0;
            background-color: transparent;
          }
        }
      }
    }
  }
}

.list-panel {
  height: auto;
  min-height: 126px;
  padding-top: 12px;

  > img {
    margin: 14px 0 8px;
  }
}

.list__panel-info {
  float: left;
  width: 50%;

  .text__state {
    > i.fa {
      margin-right: 4px;
    }
  }
}

.list__panel-info--var2 {
  float: left;
  width: 33.33333%;
}

.spot-media--title {
  color: $color-sidebar;
  font-size: 16px;
}

.spot-spotlight__text--small {
  margin-top: 10px;
  @include font-size(small);
  line-height: $panel-line-height;
}

.spot-modal {
  &.in {
    .modal-dialog {
      @include translate(0, 50%);
    }
  }
}

.spot-log__group {
  .text__panel-detail {
    margin-bottom: 16px;
    padding-left: 14px;
    text-indent: -20px;
    font-size: 14px;

    &:before {
      content: '\e9c3';
      font-family: 'icomoon';
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      min-width: 1em;
      display: inline-block;
      text-align: center;
      font-size: 16px;
      vertical-align: middle;
      position: relative;
      top: -2px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}

* {
  margin: 0;
  padding: 0;
  @include box-sizing(border-box);
}
