.spot-login__wrapper {

  width: 100%;
  max-width: 320px;
  margin: auto;
  
  .spot-logo {
    margin-top: 52px;
  }

  .alert--success {
    @extend %success;
  }

  .spot-label {
    display: block;
  }
  
  .spot-panel__login,
  .spot-modal__content {
    @extend %panel__login;    
    height: auto;
    min-height: 400px;  
    padding-top: 76px;  
    &.panel--forgot {
      height: auto;
      min-height: 200px;
      padding-top: 52px;
    }
    &.panel--reset {
      @extend %panel__forgot;
    }
    .form--title {
      @extend %form__title;
    }
    .form--text {
      @extend %form__text;
    }
    .spot-label__container {
      @include font-weight(light);
      > p {
        @include font-size(small);
      }
    }
    .checkbox-inline {
      position: relative;     
    }
    .link--helper {
      color: $color-link;
      font-size: 11px;
      text-decoration: none;
    }
    .spot-captcha {
      width: 100%;
      max-width: 320px;
      height: 76px;
      margin-bottom: 32px;
      border-radius: 3px;
      border: 1px solid #d6d6d6;
      .captcha--wrapper {
        width: 304px;
        height: auto;
        margin: auto;
      }
    }
  }

  .spot-back__root {
    a {
      display: block;
      color: $color-grey-900;
      margin-top: 32px;
      font-size: 12px;
    }
  }
  .spot-button {    
    &.btn--submit {
      &:hover {
        opacity: .9;
      }
    }
  }
}


// Global input styling
.spot-input__text {
  display: block;
  width: 100%;
  height: 52px;
  border-radius: 5px;
  border: none;
  padding: 18px 16px;
  margin-bottom: 16px;
  font-size: 13px;
  line-height: 1.42857;
  background-color: $white;
  background-image: none;
  -webkit-transition: all .1s ease-in;
  -moz-transition: all .1s ease-in;
  -ms-transition: all .1s ease-in;
  -o-transition: all .1s ease-in;
  transition: all .1s ease-in;

  &:focus {
    outline: none;
    -webkit-box-shadow: 0 0 0 1px $color-spot-blue;
    -moz-box-shadow: 0 0 0 1px $color-spot-blue;
    box-shadow: 0 0 0 1px $color-spot-blue;
  }        
  &::-webkit-input-placeholder {
    color: $color-grey-400;
  }
  &.input--error {
    outline: none;
    -webkit-box-shadow: 0 0 0 1px $alert-danger;
    -moz-box-shadow: 0 0 0 1px $alert-danger;
    box-shadow: 0 0 0 1px $alert-danger;          
  }
}
.spot-error__text {
  display: block;
  color: $alert-danger;
  font-size: 13px;
  margin: -10px 0 16px;
}
   