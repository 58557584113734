.spot-swiper {
  margin-bottom: 10px;
  background-color: $black;
  clear: both;
  .swiper-wrapper {
    .swiper-slide {
      .slide-main {
        position: relative;
        &:before {
          content: '';
          display: block;
          width: 100%;
          padding-bottom: 56.25%;
          @include breakpoint(mobilesmall) {
            padding-bottom: 67.5%;
          }
        }
        img {
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          bottom: 0;
          right: 0;
          max-width: 100%;
          max-height: 100%;
          margin: 0 auto;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
      .swiper-caption {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        background-color: rgba(80,80,80,0.75);
        text-align: left;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 1);
        line-height: normal;
        color: #fff;
        @include breakpoint(mobilesmall) {
          position: relative;
          max-height: none;
          margin-top: 10px;
          background-color: transparent;
          font-style: italic;
          text-shadow: none;
          color: #888;
        }
        .caption-text {
          display: inline-block;
          padding: 6px 90px 6px 15px;
          @include breakpoint(mobilesmall) {
            padding: 6px 90px 10px 10px;
            padding-left: 10px;
            border-left: 1px solid #d4d4d4;
          }
        }
      }
    }
  }
} 

// Arrow custom
.swiper-button-prev, .swiper-button-next {
  color: $white;
}
.swiper-button-next, 
.swiper-container-rtl .swiper-button-prev,
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: none;
  background-color: rgba(0,0,0, 0.5);
  width: 88px;
  height: 64px;
}
.swiper-button-next, 
.swiper-container-rtl .swiper-button-prev {
  right: 0;
}
.swiper-button-prev, 
.swiper-container-rtl .swiper-button-next {
  left: 0;
}
.swiper-spoticon {
  position: absolute;
  line-height: 20px;
  @include translate(-50%, -50%);
  &.spoticon-arrow-right-dark {
    top: 50%;
    left: 50%;
  }
  &.spoticon-arrow-left-dark {
    top: 50%;
    right: 28%;
  }
}
