.spot-container__preview {
  float: left;
  width: 627px;
  height: 354px;
  overflow: hidden;
  @include border-radius(3px);
}

.spot-canvas-group {
  .canvas__item {
    display: inline-block;
    width: 100%;
    height: 135px;
    max-width: 135px;
  }
}

.spot-canvas {
  display: block;
  background-color: $grey-default;
  &.large-canvas--16by9 {
    width: 100%;
    overflow: hidden;
    @include border-radius(3px);
  }

  &.canvas--16by9,
  &.canvas--4by3,
  &.canvas--1by1 {
    width: 100%;
    height: 135px;
    overflow: hidden;
    @include border-radius(3px);
  }
  #canvas-preview--16by9{
    max-width: 240px;
  }
  #canvas-preview--4by3 {
    max-width: 180px;
  }
  #canvas-preview--1by1 {
    max-width: 135px;
  }
}

// For data management add new image
.canvas-group--image {
  li {
    margin-top: 30px;
  }
  .spot-canvas {
    &.large-canvas--16by9 {
      width: 100%;
      overflow: hidden;
      @include border-radius(3px);
    }
    &.canvas--16by9,
    &.canvas--9by16,    
    &.canvas--4by3,
    &.canvas--3by4,
    &.canvas--7by5,
    &.canvas--5by7, 
    &.canvas--1by1 {
      width: 100%;
      height: 180px;
      overflow: hidden;
      @include border-radius(3px);
    }
    #large-canvas-preview--16by9 {
      max-width: 610px;
    }
    #canvas-preview--16by9{
      max-width: 320px;
    }
    #canvas-preview--9by16{
      max-width: 102px;
    }
    #canvas-preview--4by3 {
      max-width: 240px;
    }
    #canvas-preview--3by4 {
      max-width: 135px;
    }
    #canvas-preview--7by5 {
      max-width: 252px;
    }
    #canvas-preview--5by7 {
      max-width: 129px;
    }
    #canvas-preview--1by1 {
      max-width: 180px;
    }
  }
}