.panel {
  &.spot--panel {
    border: none;
    @include box-shadow(none);
  }
}

.table {
  margin-bottom: 0;
  th,
  td {
    @include font-size(small);
  }
  .spot-table__header {
    height: 42px;
    background: $color-grey-light-blue;
    
    .spot-checkbox {
      + label:before {
        top: 16px;
        left: 16px;
      }
    }
  }
  .spot-table__body {
    > tr {
      background-color: $white;
      @include border-radius(3px);
    }
  }
}

.table > thead > tr > th {
  border-bottom: none;
}

.table>thead>tr>th, 
.table>thead>tr>td, 
.table>tbody>tr>th, 
.table>tbody>tr>td, 
.table>tfoot>tr>th, 
.table>tfoot>tr>td {
  padding: 14px;
  border-top: 0;
}

.table tr, 
.table th, 
.table td, 
.htContextMenu td, 
.sidebar-user-material-menu > a, 
.navbar-nav > 
.active > a:after, 
.ranges ul li {
  border-bottom: 6px solid $bgcol-sidebar;
}

.dataTable tbody > tr:first-child > td.select-checkbox {
  width: 45px;
}

.dataTable tbody > tr.selected, 
.dataTable tbody > tr > .selected {
  background-color: $color-grey-20;
}

.spot-list-group__item {
  &.list--inline {
    @extend %list__inline;
    width: 15px;
    padding: 3px;
    margin: 0 6px;
  }
}

.spot--cell-option {
  > li > a {
    display: block;
    color: $color-mini-info;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }
}

.selectboxit-selected {
  background-color: $color-grey-80;
}

// Table styling for Content Management
// 
// ------------------------------------

.panel--pull-top {
  @include translate(0, -90px);
}

.datatable-header {
  padding-right: 0;
  padding-left: 0;
  border-bottom: none;
  font-size: 14px;
  .dataTables_filter {
    display: none;
  }
}

.datatable-footer {
  border-top: none;
  padding-right: 0;
  padding-left: 0;
  @include font-size(small);
  .dataTables_info {
    display: none;
  }
  .dataTables_paginate {
    float: none;
    width: 100%;
    max-width: 268px;
    margin: auto;
    padding: 9px 12px;
    text-align: center;
    background-color: $white;
    border-radius: 6px;
    @include box-shadow(0 2px 4px 0 rgba(0,0,0,0.1));
  }
}

.dataTables_paginate .paginate_button.current, 
.dataTables_paginate .paginate_button.current:hover, 
.dataTables_paginate .paginate_button.current:focus {
  background-color: $color-sidebar;
}

.select2-selection--single:not([class*=bg-]):not([class*=border-]) {
  border-bottom-color: transparent;
  padding-left: 8px;
  padding-right: 8px;
  background-color: $white;
  @include border-radius(5px);
}

.select2-selection--single .select2-selection__arrow:after {
  right: 8px;
}

.select2-container--focus .select2-selection--single:not([class*=bg-]):not([class*=border-]), 
.select2-container--open .select2-selection--single:not([class*=bg-]):not([class*=border-]) {
  @include box-shadow(none);
  border-bottom-color: transparent;
}

.spotlight-datatables {
  .dataTables_length {
    margin-right: 150px;
  }

  &.datatable__single-filter {
    .dataTables_length {
      margin-right: 0; // Set margin right article page
    }
  }
  .spot-cell {
    &.cell-filter--top {
      display: block; // Set display to filter channel
    }
  } 
}

// Table with filters channel

.spot-table__filters--default {
  .dataTables_length {
    margin-right: 150px;
  }
}

.spot-cell {
  display: block;
  width: 100%;
  max-width: 140px;
  &.cell-filter--top {
    position: absolute;
    top: 6px;
    right: 0;
    padding-right: 0;
    border-bottom: none;
  }
}

// Custom table for data management media image
// 
// -------------------
.table {
  .table__image {
    > tr {
      > td {
        vertical-align: middle;
        a {
          display: inline-flex;
          align-items: center;
          text-decoration: none;
          .image-wrap {
            display: block;
            width: 100%;
            max-width: 32px;
            height: 32px;
            margin-right: 8px;
          }
          .block--image {
            display: inline-block;
            width: 100%;
            max-width: 120px;
            height: 80px;
            margin-right: 16px;
            overflow: hidden;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.spot-table__body {
  .spot-audio--default {
    background-color: $grey-light;
  }
  > tr {
    > td {
      a {
        display: inline-block;  
        > p {
          display: inline-block;  
        }
      }
      .image--full-rounded {
        @include border-radius(100px);
        overflow: hidden;
      }
    }
  }
}

.table__description {
  width: 100%;
  margin-bottom: 16px;
  border: none;
  td {
    vertical-align: top;
  }
}