.spot-panel__subheader {
  padding: 20px 0;

  .spot-button--group {
    margin-bottom: 0;
    > li {
      display: inline-block;
      margin-left: 16px;
    }
  }
}

.spot-post__jumbotron,
.spot-post__text {
  position: relative;
}

.spot-post__jumbotron {
  max-width: 1000px;
  overflow: hidden;
  background-color: $bgcol-default;

  > img {
    margin: auto;
  }

  .jumbotron__highlight {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 25px 0;
    background-color: rgba(0, 0, 0, .3);
    > p {
      color: $white;
    }
  }
}

.spot-content__title {
  &.title--large {
    @include font-size(extralarge);
    @include font-weight(strong);
  }
}

.spot-panel__text-group {
  width: auto;
  font-size: 16px;
  line-height: 1.5;

  p {
    margin-top: 24px;
  }

  &.pull-left {
    max-width: 700px;
    padding-right: 20px;
  }
}

.spot-panel__container  {
  .spot-panel__extra-sidebar {
    width: 300px;
    border: 1px solid $grey-light-blue;
    @include border-radius(5px);
    
    .spot-panel {
      padding: 0;  
      .spot-panel__group {
        margin-bottom: 0 !important;
        .spot-panel--bottom.spot-panel__list-item {
          .media {
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
  
    .media-title {
      margin-left: 16px;

      &.title--danger {
        color: $color-sidebar;
        @include font-weight(strong);
      }
    }
    li.media {
      margin-top: 0; 
    }
  }
}

.content-centered-wrapper {
  max-width: 1000px;
  margin: auto;
}
