.body-error-page {
    max-width: 100%;
    overflow: hidden;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fffaf8+0,fcf0ee+100 */
    background: #fffaf8; /* Old browsers */
    background: -moz-linear-gradient(top, #fffaf8 0%, #fcf0ee 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #fffaf8 0%,#fcf0ee 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #fffaf8 0%,#fcf0ee 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fffaf8', endColorstr='#fcf0ee',GradientType=0 ); /* IE6-9 */
}
.error-page {
    width: 100%;
    display: block !important;
    &__inner {
        width: 100% !important;
        height: 100vh;
        .error-background {
            display: block;
            width: 100%;
            height: 100vh;
            .row {
                div {
                    padding: 0px !important;
                }
            }
            .bg-left {
                height: 100vh;
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fffaf8+0,fcf0ee+100 */
                background: #fffaf8; /* Old browsers */
                background: -moz-linear-gradient(top, #fffaf8 0%, #fcf0ee 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top, #fffaf8 0%,#fcf0ee 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, #fffaf8 0%,#fcf0ee 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fffaf8', endColorstr='#fcf0ee',GradientType=0 );
                
            }
            .bg-right {
                background: #fff;
                height: 100vh;
                opacity: 0.5;
            }
        }
        .error-content {
            top: 180px;
            position: absolute;
            left: 120px;
            &__inner {
                span {
                    font-size: 20px;
                    color: #ef622f;
                    font-weight: bold;
                }
                h1 {
                    font-size: 32px;
                    color: #c95ba2;
                    letter-spacing: 0.2px;
                    margin-bottom: 38px;
                    margin-top: 10px;
                    font-weight: bold;
                }
                .text {
                    width: 100%;
                    max-width: 403px;
                    p {
                        color: #352d32;
                        font-size: 16px;
                    }
                }
                a.block-btn {
                    color: #fff;
                    font-size: 12px;
                    letter-spacing: 1px;
                    border-radius: 4px;
                    box-shadow: 0px 2px 5px #f061313d;
                    padding: 16px 40px;
                    font-weight: 400;
                    text-transform: uppercase;
                    background: #c95aa4;
                    background: -webkit-linear-gradient(left, #c95aa4 0%, #f06131 100%);
                    background: linear-gradient(to right, #c95aa4 0%, #f06131 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c95aa4', endColorstr='#f06131',GradientType=1 );
                    border: none;
                    &:hover {
                        box-shadow: 0px 10px 30px #f061313d;
                    }
                }

                .inline-btn {
                    font-size: 12px;
                    color: #ef622f;
                    font-style: italic;
                    text-decoration: underline;
                    font-weight: bold;
                    display: block;
                    text-align: left;
                    padding-left: 0px;
                    margin-top: 10px;
                    width: -webkit-fit-content;
                    width: -moz-fit-content;
                    width: fit-content;
                    &:hover {
                        background: transparent !important;
                    }
                }
            }
        }
        .error-element {
            .el-1 {
                bottom: 0;
                position: absolute;
                width: 100%;
                max-width: 331px;
            }
            .el-2 {
                position: absolute;
                right: 64px;
                bottom: 64px;
            }
        }
    }
}


@media only screen and (max-width: 768px) {
    .error-content {
        top: 80px !important;
        width: 100%;
        padding: 0px 50px;
        left: 0px !important;
    }

    .bg-left {
        height: 80vh !important;
    }

    .error-background .bg-right {
        background: #fff;
        height: 20vh !important;
        opacity: 0.5;
    }

    .error-element .el-1 {
        bottom: 81px !important;
        max-width: 238px !important;
    }

    .error-element .el-2 {
        position: absolute;
        left: 50px;
        bottom: 45px !important;
        right: 0 !important;
    }

    .error-page {
        width: 100%;
        display: block !important;
        height: 100vh;
        overflow: hidden;
    }
}