.spot-breadcrumb {
  background: transparent;
  margin-bottom: 20px;
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
  @include translate(0, -20px);

  &.breadcrumb-article {
    li {
      @include font-size(small);
      @include font-weight(strong);
      text-transform: none;
    }
  }

  li {
    font-size: 11px;
    text-transform: uppercase;
    >a {
      color: $color-grey-900;
      font-weight: bold;
    }
  }
}

.breadcrumb>li+li:before {
  content: "// ";
  padding: 0 5px;
  color: $color-grey-border;
}
